<template>
  <div class="Workspace">
    <v-row class="Workspace__filter-row">
      <v-text-field
        class="item"
        v-on:input="debounceInput"
        outlined
        dense
        v-model="search"
        name="Search"
        label="Search"
      />
      <v-btn
        v-if="isPrivileged()"
        @click.stop="isCreateDialogOpen = true"
        class="item"
        outlined
        dense
        name="Create"
        label="Create"
      >
        Create New Agent
      </v-btn>
    </v-row>

    <v-row v-if="!workspacesLoading">
      <div class="Workspace__listContainer">
        <div
          class="Workspace__listItem"
          v-for="workspace in workspaces"
          :key="workspace.id"
          @click.stop="goToWorkspace(workspace)"
        >
          <div class="itemSection">
            <span class="title">
              <v-icon v-if="workspace.private" big class="private">
                mdi-lock
              </v-icon>
              <span>{{ workspace.name }}</span>
            </span>
          </div>
          <div class="itemSection">
            <span class="description">{{ workspace.description }}</span>
          </div>
          <div class="itemSection" v-if="workspace?.tools?.length > 0">
            <div class="toolList">
              <div
                class="toolItem"
                v-for="tool in workspace.tools"
                :key="tool.id"
              >
                {{ tool.name }}
              </div>
            </div>
          </div>
          <div class="itemSection">
            <span class="recentActivity">
              Created: {{ getTime(workspace.createdAt) }}
            </span>
          </div>
          <div class="deleteWorkspaceBtn" v-if="isPrivileged">
            <v-btn
              icon
              @click="
                openDeleteConfirm = true;
                deleteWorkspaceId = workspace.id;
              "
            >
              <v-icon style="color: red"> mdi-delete </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-row>

    <v-row v-if="workspacesLoading">
      <Loader></Loader>
    </v-row>
    <create-workspace
      :dialog="isCreateDialogOpen"
      @close="isCreateDialogOpen = false"
    />

    <v-dialog
      v-model="openDeleteConfirm"
      max-width="315px"
      class="DeletePromptModal"
    >
      <v-card outlined>
        <v-card-title class="DeletePromptModal__title">
          <v-row>
            <v-col cols="auto" class="mr-auto"> Delete Agent </v-col>
            <v-col cols="auto">
              <v-btn
                icon
                @click="
                  openDeleteConfirm = false;
                  deleteWorkspaceId = null;
                "
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="DeletePromptModal__content">
          <p>
            Are you sure you want to delete this agent? All knowledge base,
            sessions and messages will be deleted.
          </p>
          <v-row>
            <v-col>
              <v-btn
                plain
                @click="
                  openDeleteConfirm = false;
                  deleteWorkspaceId = null;
                "
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                @click="delWorkspace({ workspaceId: deleteWorkspaceId })"
                :loading="deleteWorkspaceLoading"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: workspacesGetters, mapActions: workspaceActions } =
  createNamespacedHelpers('aiWorkspace');

import debounce from 'lodash/debounce';
import CreateWorkspace from '@/views/ai-workspaces/create-workspace/create-workspace.vue';
import Loader from '@/components/loader/loader.vue';
import * as moment from 'moment';
import lodashGet from 'lodash/get';

export default {
  name: 'AI Agents',
  components: { Loader, CreateWorkspace },
  data() {
    return {
      search: '',
      isCreateDialogOpen: false,
      openDeleteConfirm: false,
      deleteWorkspaceId: null,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
      user: 'USER',
    }),
    ...workspacesGetters({
      workspaces: 'WORKSPACES_LIST',
      workspacesLoading: 'WORKSPACE_LIST_LOADING',
      deleteWorkspaceLoading: 'IS_DELETE_WORKSPACE_LOADING',
    }),
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.getWorkspaces({
        search: this.search,
        topLevelAdmin: this.isShAdmin(),
      });
    },
  },
  methods: {
    ...workspaceActions(['getWorkspaces', 'deleteWorkspace']),
    async delWorkspace() {
      await this.deleteWorkspace({ workspaceId: this.deleteWorkspaceId });
      this.deleteWorkspaceId = null;
      this.openDeleteConfirm = false;
      await this.getWorkspaces({
        search: this.search,
        topLevelAdmin: this.isShAdmin(),
      });
    },
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    getTime(date) {
      return moment(date).fromNow();
    },
    goToWorkspace(item) {
      if (this.openDeleteConfirm) {
        return;
      }
      this.$router.push({ path: `/agents/${item.id}` });
    },
    isPrivileged() {
      return this.user?.is_privileged;
    },
    debounceInput: debounce(function (e) {
      this.search = e;
      this.getWorkspaces({
        search: this.search,
        topLevelAdmin: this.isShAdmin(),
      });
    }, 1000),
  },
  mounted() {
    this.getWorkspaces({ search: this.search });
  },
};
</script>

<style lang="scss" scoped>
@import './ai-workspaces';
</style>
